@if (toggle) {
  <svg xmlns="http://www.w3.org/2000/svg" fill="#000" viewBox="0 0 24 24">
    <path
      d="M23.492 6.275L17.728.51a1.481 1.481 0 00-2.3.253l-4.1 6.443a7.468 7.468 0 00-5.468.8 1.48 1.48 0 00-.3 2.325l8.105 8.1a1.5 1.5 0 001.235.422 1.483 1.483 0 001.09-.72 7.423 7.423 0 00.8-5.467l6.443-4.1a1.48 1.48 0 00.253-2.295l.006.004zm-6.2-2.848l-2.12 3.333a.508.508 0 01-.69.153.5.5 0 01-.154-.69l2.121-3.332a.501.501 0 01.918.353.502.502 0 01-.075.183zM6.454 15.428l-5.94 5.94a1.5 1.5 0 000 2.121 1.517 1.517 0 002.121 0l5.94-5.94a1.5 1.5 0 00-2.121-2.121z"
    />
  </svg>
} @else {
  <svg xmlns="http://www.w3.org/2000/svg" fill="#000" viewBox="0 0 24 24">
    <g clip-path="url(#clip0)">
      <path
        d="M.75 23.999a.743.743 0 01-.53-.22.743.743 0 01-.22-.53c0-.2.078-.389.22-.53l7.474-7.474-3.575-3.575a2.248 2.248 0 01-.588-2.16c.151-.582.52-1.07 1.039-1.374a8.266 8.266 0 015.564-1.002l3.877-6.094c.089-.139.192-.268.308-.383a2.231 2.231 0 011.59-.659c.601 0 1.166.234 1.591.659L23.343 6.5a2.236 2.236 0 01.605 2.079 2.238 2.238 0 01-.988 1.41l-6.092 3.877a8.257 8.257 0 01-1 5.562 2.234 2.234 0 01-1.942 1.114 2.239 2.239 0 01-1.593-.661l-3.576-3.577L1.28 23.78a.746.746 0 01-.53.219zM8.72 8.513c-1.186 0-2.36.318-3.394.919a.746.746 0 00-.148 1.175l8.214 8.214c.142.142.331.22.532.22a.743.743 0 00.646-.369 6.737 6.737 0 00.728-4.985.75.75 0 01.326-.808l6.529-4.155a.748.748 0 00.128-1.163L16.44 1.718a.745.745 0 00-.531-.22.743.743 0 00-.633.348l-4.155 6.53a.746.746 0 01-.808.326 6.809 6.809 0 00-1.593-.189z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
}
