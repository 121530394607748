import { Routes } from "@angular/router";

export enum RouteKey {
  Root = "Root",
}

export const routes = {
  Root: "behandle-pkb",
};

export const managePkbPageLazyRoutes: Routes = [
  {
    path: "",
    loadComponent: () => import("./manage-pkb-page.component").then((c) => c.ManagePkbPageComponent),
  },
];
