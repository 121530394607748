import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter } from "rxjs/operators";

interface Breadcrumb {
  label: string;
  url: string;
}

@Injectable({ providedIn: "root" })
export class BreadcrumbsService {
  // eslint-disable-next-line rxjs/finnish
  public breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
  public breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  constructor(private readonly router: Router) {
    this.updateBreadcrumbs();

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.updateBreadcrumbs();
    });
  }

  private updateBreadcrumbs(): void {
    this.resolveBreadcrumbs(this.router.routerState.snapshot.root).subscribe((breadcrumbs) => {
      this.breadcrumbsSubject.next(breadcrumbs);
    });
  }

  private resolveBreadcrumbs(
    route: ActivatedRouteSnapshot | null,
    url: string = "",
    breadcrumbs: Breadcrumb[] = [],
  ): Observable<Breadcrumb[]> {
    if (!route) {
      return of(breadcrumbs);
    }

    const pathSegments = route.url.map((segment) => segment.path);
    const nextUrl = `${url}/${pathSegments.join("/")}`.replace(/\/$/, ""); // Trim trailing slashes

    if (route.routeConfig && route.data?.["breadcrumb"]) {
      const breadcrumbLabel = route.data["breadcrumb"];

      if (breadcrumbLabel && breadcrumbLabel !== "skip-breadcrumb") {
        const isDuplicate = breadcrumbs.some((b) => b.url === nextUrl);
        if (!isDuplicate) {
          breadcrumbs.push({ label: breadcrumbLabel, url: nextUrl });
        }
      }
    }

    return route.firstChild ? this.resolveBreadcrumbs(route.firstChild, nextUrl, breadcrumbs) : of(breadcrumbs);
  }
}
