import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FmsPipe } from "src/app/modules/shared/pipes/fms.pipe";
import { AsyncPipe } from "@angular/common";
import { RouterLink } from "@angular/router";
import { BreadcrumbsService } from "src/app/services/breadcrumbs.service";
import { FmsService } from "src/app/services/fms.service";
import { firstValueFrom, map, Observable } from "rxjs";
import { OBSERVER_BREAKPOINT_MD_MIN } from "src/app/constants/technical.constants";
import { BreakpointObserver } from "@angular/cdk/layout";
import { LetDirective } from "src/app/modules/shared/directives/let.directive";
import { IconComponent } from "src/app/components/icon/icon.component";

@Component({
  selector: "app-breadcrumbs-rebrand",
  standalone: true,
  imports: [FmsPipe, AsyncPipe, RouterLink, LetDirective, IconComponent],
  templateUrl: "./breadcrumbs.component.html",
  styleUrl: "./breadcrumbs.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  public breadcrumbs$ = this.breadcrumbsService.breadcrumbs$;
  public readonly isMobile$: Observable<boolean>;
  constructor(
    private readonly breadcrumbsService: BreadcrumbsService,
    private readonly fmsService: FmsService,
    private readonly windowSizeObserver: BreakpointObserver,
  ) {
    this.isMobile$ = this.windowSizeObserver.observe(OBSERVER_BREAKPOINT_MD_MIN).pipe(map(({ matches }) => !matches));
  }

  public async goToOverview(): Promise<void> {
    window.location.href = await firstValueFrom(this.fmsService.translateAsync("breadcrumbs.overview.url"));
  }
}
