<h1>{{ "common.pagenotfound.header" | fms }}</h1>

<section class="mt-48">
  <img alt [src]="'common.pagenotfound.imageRebrand' | fms" />
</section>
<section class="mt-32">
  <a routerLink="/">
    <button stbButton="contained" [setIconBefore]="true" [iconType]="'arrow-left'" [rebrand]="true">
      {{ "common.pagenotfound.backButtonLabel" | fms }}
    </button>
  </a>
</section>
