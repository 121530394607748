import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-pin",
  templateUrl: "./pin.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class PinComponent {
  @Input()
  public toggle = false;
}
