<ng-container *appLet="isMobile$ | async as isMobile">
  <ng-container *appLet="breadcrumbs$ | async as breadcrumbs">
    <nav>
      <a [href]="'breadcrumbs.overview.url' | fms" (click)="goToOverview()">
        @if (isMobile) {
          @if (breadcrumbs && breadcrumbs.length < 2) {
            <span>{{ "breadcrumbs.overview" | fms }}</span>
          } @else {
            <span>...</span>
          }
        } @else {
          <span>{{ "breadcrumbs.overview" | fms }}</span>
        }
        <app-icon [name]="'chevron_right'"></app-icon>
      </a>

      @for (breadcrumb of breadcrumbs; track breadcrumb.label; let i = $index) {
        @if (i === breadcrumbs.length - 1) {
          <span class="current">{{ breadcrumb.label | fms: { logMissing: false } }}</span>
        } @else {
          <a [routerLink]="[breadcrumb.url]" queryParamsHandling="merge">
            @if (breadcrumbs.length > 2 && i === breadcrumbs.length - 3 && isMobile) {
              <span>...</span>
            } @else {
              <span>{{ breadcrumb.label | fms: { logMissing: false } }}</span>
            }
            <app-icon [name]="'chevron_right'"></app-icon>
          </a>
        }
      }
    </nav>
  </ng-container>
</ng-container>
