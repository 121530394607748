import { Component } from "@angular/core";
import { ErrorsService, RoleError } from "src/app/services/errors.service";
import { Observable } from "rxjs";
import { AlertModule } from "@storeblocks/alert-ng";
import { AsyncPipe } from "@angular/common";
import { FmsPipe } from "src/app/modules/shared/pipes/fms.pipe";

@Component({
  selector: "app-role-error",
  templateUrl: "role-errors.component.html",
  standalone: true,
  imports: [AlertModule, AsyncPipe, FmsPipe],
})
export class RoleErrorsComponent {
  readonly roleErrors$: Observable<RoleError[]>;
  constructor(private readonly errorsService: ErrorsService) {
    this.roleErrors$ = this.errorsService.roleErrors$;
  }
}
