import { Routes } from "@angular/router";
import {
  actionsLazyRoutes,
  RouteKey as ActionsRouteKey,
  routes as actionsRoutes,
} from "src/app/pages/actions-page/actions.routing";
import { RouteKey as ArticlesRouteKey, routes as articleRoutes } from "src/app/pages/articles-page/routes";
import { RouteKey as ContractsRouteKey, routes as contractsRoutes } from "src/app/pages/contracts-page/routes";
import {
  RouteKey as InvestmentOptionsRouteKey,
  routes as investmentOptionsRoutes,
} from "src/app/pages/investment-options-page/routes";
import {
  needsLazyRoutes,
  RouteKey as NeedsRouteKey,
  routes as needsRoutes,
} from "src/app/pages/needs-page/needs.routing";
import {
  RouteKey as ManagePkbRouteKey,
  routes as managePkbRoutes,
} from "src/app/pages/manage-pkb-page/manage-pkb.routing";
import {
  RouteKey as PlanRouteKey,
  deprecatedRoutes as planDeprecatedRoutes,
  routes as planRoutes,
} from "src/app/modules/pension-plan/routes";
import {
  RouteKey as SmartAccountRouteKey,
  routes as smartAccountRoutes,
} from "src/app/pages/smart-account-page/routes";
import { navigateToPensionPlanResolver } from "src/app/resolvers/navigate-to-pension-plan.resolver";
import { environment } from "src/environments/environment";
import { canActivateApp } from "./guards/auth.guard";
import { smartAccountResolver } from "./pages/smart-account-page/resolvers/smart-account.resolver";
import {
  apiHomeResolver,
  contractSalaryResolver,
  fmsResolver,
  portfolioInsightResolver,
} from "./resolvers/app.resolver";
import {
  afpResolver,
  bankResolver,
  navResolver,
  norskpensjonResolver,
  otherPensionEngagementsResolver,
  publicPensionResolver,
  savingsAndPensionResolver,
  unavailableNorskpensjonResolver,
} from "./resolvers/engagement.resolver";
import { removeAfpInPublicContextResolver } from "./resolvers/remove-afp-in-public-context.resolver";
import {
  benefitsResolver,
  consentResolver,
  customerResolver,
  customerSuppliedClientDataResolver,
  customerSuppliedDataResolver,
  languageResolver,
} from "./resolvers/user.resolver";
import { RouteKey, routes } from "./routes";
import {
  RouteKey as SavingForPensionKey,
  routes as savingForPensionRoutes,
} from "src/app/pages/saving-for-pension-page/routes";
import { pensionPlanLazyRoutes } from "src/app/modules/pension-plan/pension-plan.routing";
import { smartAccountLazyRoutes } from "src/app/pages/smart-account-page/smart-account.routing";
import { investmentOptionsLazyRoutes } from "src/app/pages/investment-options-page/investment-options.routing";
import { articlesLazyRoutes } from "src/app/pages/articles-page/articles.routing";
import { savingForPensionLazyRoutes } from "src/app/pages/saving-for-pension-page/saving-for-pension.routing";
import { managePkbPageLazyRoutes } from "src/app/pages/manage-pkb-page/manage-pkb.routing";
import { PageNotFoundComponent } from "src/app/pages/pagenotfound/pagenotfound.component";
import { contractsLazyRoutes } from "src/app/pages/contracts-page/contracts.routing";
/**
 * Renamed or deprecated routes for backward compability.
 * A visit to any route in this list will trigger a monitor warning, which is
 * useful for catching old links spread around both inside and outside
 * the application.
 */
export const monitoredDeprecatedUrls: string[] = Object.values(planDeprecatedRoutes).map(
  (value) => `${planRoutes[PlanRouteKey.Root]}/${value}`,
);

export const appRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        resolve: {
          apiHomeResolver,
        },
        children: [
          {
            path: "",
            canActivateChild: environment.offlineMode ? [] : [canActivateApp],
            resolve: [],
            children: [
              {
                path: "",
                resolve: {
                  customerResolver,
                  contractSalaryResolver,
                  languageResolver,
                },
                children: [
                  {
                    path: "",
                    resolve: {
                      consentResolver,
                      fmsResolver,
                    },
                    children: [
                      {
                        path: "",
                        resolve: {
                          customerSuppliedDataResolver,
                          customerSuppliedClientDataResolver,
                        },
                        children: [
                          {
                            path: "",
                            resolve: {
                              removeAfpInPublicContextResolver,
                              afpResolver,
                              bankResolver,
                              benefitsResolver,
                              navResolver,
                              norskpensjonResolver,
                              otherPensionEngagementsResolver,
                              publicPensionResolver,
                              savingsAndPensionResolver,
                              smartAccountResolver,
                              unavailableNorskpensjonResolver,
                              portfolioInsightResolver,
                            },
                            children: [
                              {
                                path: routes[RouteKey.Root],
                                resolve: { navigateToPensionPlanResolver },
                                loadComponent: () =>
                                  import("./pages/dashboard-page/dashboard-page.component").then(
                                    (c) => c.DashboardPageComponent,
                                  ),
                                data: {
                                  breadcrumb: "breadcrumbs.smartPension",
                                },
                              },
                              {
                                path: routes[RouteKey.Root],
                                data: {
                                  breadcrumb: "breadcrumbs.smartPension",
                                },
                                children: [
                                  {
                                    path: routes[RouteKey.ContactUs],
                                    // deprecated contact us page, move user to Articles page
                                    redirectTo: "/nyttig-om-pensjon",
                                    pathMatch: "full",
                                  },
                                  {
                                    path: routes[RouteKey.Concept],
                                    loadComponent: () =>
                                      import("./pages/concept-page/concept-page.component").then(
                                        (c) => c.ConceptPageComponent,
                                      ),
                                    data: {
                                      breadcrumb: "breadcrumbs.concept",
                                    },
                                  },
                                  {
                                    path: planRoutes[PlanRouteKey.Root],
                                    children: pensionPlanLazyRoutes,
                                    data: {
                                      breadcrumb: "skip-breadcrumb",
                                    },
                                  },
                                  {
                                    path: smartAccountRoutes[SmartAccountRouteKey.Root],
                                    children: smartAccountLazyRoutes,
                                    data: {
                                      breadcrumb: "breadcrumbs.smartAccount",
                                    },
                                  },
                                  {
                                    path: investmentOptionsRoutes[InvestmentOptionsRouteKey.Root],
                                    children: investmentOptionsLazyRoutes,
                                    data: {
                                      breadcrumb: "breadcrumbs.investmentOptions",
                                    },
                                  },
                                  {
                                    path: articleRoutes[ArticlesRouteKey.Root],
                                    children: articlesLazyRoutes,
                                    data: {
                                      breadcrumb: "breadcrumbs.faq",
                                    },
                                  },
                                  {
                                    path: contractsRoutes[ContractsRouteKey.Root],
                                    children: contractsLazyRoutes,
                                    data: {
                                      breadcrumb: "breadcrumbs.contracts",
                                    },
                                  },
                                  {
                                    path: actionsRoutes[ActionsRouteKey.Root],
                                    children: actionsLazyRoutes,
                                    data: {
                                      breadcrumb: "breadcrumbs.actions",
                                    },
                                  },
                                  {
                                    path: needsRoutes[NeedsRouteKey.Root],
                                    children: needsLazyRoutes,
                                    data: {
                                      breadcrumb: "breadcrumbs.needs",
                                    },
                                  },
                                  {
                                    path: managePkbRoutes[ManagePkbRouteKey.Root],
                                    children: managePkbPageLazyRoutes,
                                    data: {
                                      breadcrumb: "breadcrumbs.managePkb",
                                    },
                                  },
                                  {
                                    path: savingForPensionRoutes[SavingForPensionKey.Root],
                                    children: savingForPensionLazyRoutes,
                                    data: {
                                      breadcrumb: "breadcrumbs.savingForPension",
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: "**",
                component: PageNotFoundComponent,
                resolve: [fmsResolver],
                data: {
                  breadcrumb: "breadcrumbs.pageNotFound",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
