import { Injectable } from "@angular/core";
import { DialogService } from "src/app/services/dialog/dialog.service";
import { Cookies, CookieService } from "src/app/services/cookie.service";
import { RebrandingModalComponent } from "src/app/components/rebranding-modal/rebranding-modal.component";
import { FmsService } from "src/app/services/fms.service";
import { first } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RebrandingService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly cookieService: CookieService,
    private readonly fmsService: FmsService,
  ) {}

  public startModal(): void {
    this.fmsService
      .translateAsync<{ value: boolean }>("modal.rebranding.show")
      .pipe(first())
      .subscribe((value) => {
        if (!value.value) {
          return;
        }

        if (this.isRebrandingModalShown()) {
          return;
        }
        setTimeout(() => {
          const oneYearInSeconds = 31536000;
          this.cookieService.setCookie(Cookies.RebrandingModalShown, "true", oneYearInSeconds);
          this.dialogService.open(RebrandingModalComponent, {});
        }, 5000);
      });
  }

  private isRebrandingModalShown(): boolean {
    return !!this.cookieService.getCookie(Cookies.RebrandingModalShown);
  }
}
