import { ChangeDetectionStrategy, Component } from "@angular/core";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import { PensionPlanService } from "src/app/services/pension-plan.service";
import { AfpFormFieldComponent } from "src/app/modules/shared/components/form-fields/afp-form-field/afp-form-field.component";
import { StartPayoutAgeFormFieldComponent } from "src/app/modules/shared/components/form-fields/start-payout-age-form-field/start-payout-age-form-field.component";

@Component({
  selector: "app-user-settings-panel",
  templateUrl: "./user-settings-panel.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AfpFormFieldComponent, StartPayoutAgeFormFieldComponent],
})
export class UserSettingsPanelComponent {
  constructor(
    public commonParametersService: CommonParametersService,
    private readonly pensionPlanService: PensionPlanService,
  ) {}

  public onPayoutAgeChange(age: number): void {
    this.pensionPlanService.changeStartPayoutAge(age);
  }
}
