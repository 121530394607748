import { Injectable } from "@angular/core";
import { Cookies, CookieService } from "src/app/services/cookie.service";
import { switchMap, tap } from "rxjs/operators";
import { EndpointService } from "src/app/services/endpoint.service";
import { first, map, Observable, of } from "rxjs";
import { KeycloakService } from "src/app/services/keycloak.service";
import { SessionStorageService, StorageKey } from "src/app/services/session-storage.service";

@Injectable({
  providedIn: "root",
})
export class DataTrackingService {
  constructor(
    private readonly endpointService: EndpointService,
    private readonly cookieService: CookieService,
    private readonly keycloakService: KeycloakService,
    private readonly sessionStorageService: SessionStorageService,
  ) {}

  public sendDataTracking(): Observable<unknown> {
    if (this.hasSendDataTrackingSent()) {
      return of(null);
    }

    return this.getCmId().pipe(
      switchMap((cmId) => {
        if (!cmId) {
          return of(null);
        }

        return this.endpointService.composeSendDataTrackingUrl().pipe(
          switchMap((url) => {
            return this.endpointService
              .httpPost$(url, {
                body: {
                  cmId,
                  stbId: this.getStbId(),
                },
              })
              .pipe(tap(() => this.setSendDataTrackingSent()));
          }),
        );
      }),
    );
  }

  private getCmId(): Observable<string | undefined> {
    return this.keycloakService.cmid.user$.pipe(
      first(),
      map((userId) => userId),
    );
  }

  private setSendDataTrackingSent(): void {
    this.sessionStorageService.set(StorageKey.DataTrackingSent, true);
  }

  private hasSendDataTrackingSent(): boolean {
    return Boolean(this.sessionStorageService.get(StorageKey.DataTrackingSent, false));
  }

  private getStbId(): string {
    const stbId = this.cookieService.getCookie(Cookies.StbId);
    return stbId ? String(stbId) : this.generateStbIdAndSetCookie();
  }

  private generateStbIdAndSetCookie(): string {
    const stbId = this.generateStbId();
    const twoYearsInSeconds = 60 * 60 * 24 * 365 * 2;
    this.cookieService.setCookie(Cookies.StbId, stbId, twoYearsInSeconds);
    return stbId;
  }

  private generateStbId(): string {
    return "xxxxxxxx-xxxx-8xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = (Math.random() * 16) | 0;
      // eslint-disable-next-line no-bitwise
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16).toUpperCase();
    });
  }
}
