@switch (level) {
  @case ("fetch") {
    <button
      [size]="'small'"
      [stbButton]="'outlined'"
      [setIconAfter]="true"
      [iconType]="'chevron-right'"
      [rebrand]="true"
      (click)="level = 'menus'"
    >
      Fetch
    </button>
  }
  @case ("menus") {
    <button
      [size]="'small'"
      [stbButton]="'outlined'"
      [setIconAfter]="true"
      [iconType]="'chevron-right'"
      [rebrand]="true"
      (click)="level = 'engagements'"
    >
      Engagements
    </button>
    <button
      [size]="'small'"
      [stbButton]="'outlined'"
      [setIconAfter]="true"
      [iconType]="'chevron-right'"
      [rebrand]="true"
      (click)="level = 'others'"
    >
      Other
    </button>
    <button
      [size]="'small'"
      [stbButton]="'outlined'"
      [rebrand]="true"
      (click)="level = 'fetch'"
      [setIconBefore]="true"
      [iconType]="'chevron-left'"
    >
      Back
    </button>
  }
  @case ("engagements") {
    <button [size]="'small'" [stbButton]="'contained'" [rebrand]="true" (click)="fetchAfpEngagements()">afp</button>
    <button [size]="'small'" [stbButton]="'contained'" [rebrand]="true" (click)="fetchBankEngagements()">bank</button>
    <button [size]="'small'" [stbButton]="'contained'" [rebrand]="true" (click)="fetchExternalEngagements()">
      external
    </button>
    <button [size]="'small'" [stbButton]="'contained'" [rebrand]="true" (click)="fetchNpEngagements()">
      norskpensjon
    </button>
    <button
      [size]="'small'"
      [stbButton]="'outlined'"
      [rebrand]="true"
      (click)="level = 'menus'"
      [setIconBefore]="true"
      [iconType]="'chevron-left'"
    >
      Back
    </button>
  }
  @case ("others") {
    <button [size]="'small'" [stbButton]="'contained'" [rebrand]="true" (click)="fetchConsents()">
      consent-rest-api
    </button>
    <button [size]="'small'" [stbButton]="'contained'" [rebrand]="true" (click)="fetchCustomer()">
      customer-rest-api
    </button>
    <button
      [size]="'small'"
      [stbButton]="'outlined'"
      [setIconAfter]="true"
      [iconType]="'chevron-right'"
      [rebrand]="true"
      (click)="level = 'customer-supplied-api'"
    >
      customer-supplied-rest-api
    </button>
    <button [size]="'small'" [stbButton]="'contained'" [rebrand]="true" (click)="fetchTexts()">fms-rest-api</button>
    <button [size]="'small'" [stbButton]="'contained'" [rebrand]="true" (click)="fetchBenefits()">
      benefits-rest-api
    </button>
    <button
      [size]="'small'"
      [stbButton]="'outlined'"
      [rebrand]="true"
      (click)="level = 'menus'"
      [setIconBefore]="true"
      [iconType]="'chevron-left'"
    >
      Back
    </button>
  }

  @case ("customer-supplied-api") {
    <button [size]="'small'" [stbButton]="'contained'" [rebrand]="true" (click)="fetchProfile()">profile</button>
    <button [size]="'small'" [stbButton]="'contained'" [rebrand]="true" (click)="fetchClientData()">
      supplied-data
    </button>
    <button
      [size]="'small'"
      [stbButton]="'outlined'"
      [rebrand]="true"
      (click)="level = 'others'"
      [setIconBefore]="true"
      [iconType]="'chevron-left'"
    >
      Back
    </button>
  }
}
