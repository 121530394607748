<app-information-message [alertKey]="AlertKey.Dashboard"></app-information-message>
<section>
  <div class="font-heading-1 mb-08">{{ errors.headline$ | async }}</div>

  <p class="mb-16">{{ errors.information$ | async }}</p>

  <section>
    <button [rebrand]="true" stbButton="contained" (click)="reloadPage()">
      {{ errors.buttonText$ | async }}
    </button>
  </section>

  <img alt [src]="errors.image$ | async" />
</section>
