<main>
  <app-menu></app-menu>

  @if (showDevPanel$ | async) {
    <app-dev class="hide-only-on-print"></app-dev>
  }

  @if (hasCriticalErrors$ | async) {
    <section class="container">
      <app-errors></app-errors>
    </section>
  } @else if (hasRoleErrors$ | async) {
    <app-role-error></app-role-error>
  } @else {
    <div class="outlet">
      @if (outlet.isActivated) {
        <div class="container hide-only-on-print">
          <app-breadcrumbs-rebrand></app-breadcrumbs-rebrand>
        </div>
      } @else {
        <stb-loading-indicator-dots
          class="absolute-spinner"
          [size]="'large'"
          [colorVariant]="'onGrey'"
          [rebrand]="true"
        ></stb-loading-indicator-dots>
      }

      <router-outlet id="router-outlet" tabindex="-1" #outlet="outlet"></router-outlet>
    </div>
  }

  <app-footer></app-footer>
</main>

<app-refresh-modal></app-refresh-modal>
