import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, LOCALE_ID } from "@angular/core";
import { appRoutes } from "src/app/app.routing";
import { environment } from "src/environments/environment";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { InMemoryDataService } from "src/app/services/in-memory-data.service";
import { MockGraphqlClientsModule } from "src/app/modules/graphql-clients/mock-graphql-clients.module";
import { GraphqlClientsModule } from "src/app/modules/graphql-clients/graphql-clients.module";
import { APP_ERROR_HANDLER_OPTIONS, AppErrorHandler } from "src/app/providers/app-error-handler.provider";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CachingInterceptor } from "src/app/interceptors/caching.interceptor";
import { CorrelationInterceptor } from "src/app/interceptors/correlation.interceptor";
import { KeycloakBearerInterceptor } from "src/app/interceptors/keycloak-bearer.interceptor";
import { HttpCache, HttpCacheImpl } from "src/app/services/http-cache.service";
import { Monitoring } from "src/app/utils/monitoring";
import { provideRouter, Router, RouteReuseStrategy } from "@angular/router";
import { keycloakInitializer } from "src/app/initializers/keycloak.initializer";
import { KeycloakService } from "src/app/services/keycloak.service";
import { CustomRouteReuseStrategy } from "src/app/providers/custom-route-reuse-strategy.provider";
import { provideAnimations } from "@angular/platform-browser/animations";
import { FmsService } from "src/app/services/fms.service";
import { AfpService } from "src/app/services/prognoses-services/afp.service";
import { EngagementsService } from "src/app/services/engagements.service";
import { ApiHomeService } from "src/app/services/api-home.service";
import { CustomerService } from "src/app/services/customer.service";
import { ErrorsService } from "src/app/services/errors.service";
import { ActionIpsService } from "src/app/modules/features/pension-actions/components/action-ips/action-ips.service";
import { ExtrapensionService } from "src/app/services/extrapension.service";
import { InternalSavingsService } from "src/app/services/internal-savings.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { ActionEpkService } from "src/app/modules/features/pension-actions/services/action-epk.service";
import { CurrencyPipe, DatePipe, DecimalPipe, registerLocaleData } from "@angular/common";
import localeNb from "@angular/common/locales/nb";

registerLocaleData(localeNb);

const SERVICES = [
  AfpService,
  EngagementsService,
  ApiHomeService,
  CustomerService,
  ErrorsService,
  FmsService,
  ExtrapensionService,
  ActionIpsService,
  InternalSavingsService,
  KeycloakService,
  FetchPrognosesRunningJobsService,
  ActionEpkService,
];

const PIPES = [CurrencyPipe, DatePipe, DecimalPipe];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    importProvidersFrom(
      environment.production
        ? []
        : HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
            passThruUnknownUrl: true,
            post204: true,
          }),
      environment.offlineMode ? MockGraphqlClientsModule : GraphqlClientsModule,
    ),
    SERVICES,
    { provide: LOCALE_ID, useValue: "nb-NO" },
    PIPES,
    { provide: APP_ERROR_HANDLER_OPTIONS, useValue: { logErrors: true } },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorrelationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
    },
    {
      provide: HttpCache,
      useClass: HttpCacheImpl,
    },
    {
      provide: Monitoring.getTraceService(),
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakInitializer,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): void => {
        // noop
      },
      deps: [Monitoring.getTraceService()],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
};
