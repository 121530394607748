<stb-modal
  [noPMargins]="true"
  [rebrand]="true"
  [isShowing]="true"
  [large]="true"
  (cancelClick)="close()"
  (primaryClick)="refresh()"
  [primaryText]="'refreshModal.action' | fms"
  [header]="'refreshModal.title' | fms"
>
  <p>
    {{ "refreshModal.description" | fms }}
  </p>
</stb-modal>
