@if (isAdvisorContext$ | async) {
  <div class="my-16">
    <section class="mb-16">
      <stb-checkbox [rebrand]="true">
        <input
          type="checkbox"
          (change)="toggleIncognitoMode($event)"
          [checked]="advisorIncognitoMode$ | async"
          name="fruit"
        />
        <label>Rådgiver inkognitomodus</label>
      </stb-checkbox>
    </section>
    <app-list-advisor-accesses></app-list-advisor-accesses>
  </div>
}
