<stb-modal
  [noPMargins]="true"
  [rebrand]="true"
  [isShowing]="true"
  [primaryText]="'modal.rebranding.button.text' | fms"
  (primaryClick)="close()"
  (cancelClick)="close()"
  [cancelTrackId]="''"
  [forceClickOnButtons]="true"
  [header]="'modal.rebranding.title' | fms"
>
  <p class="mb-16">{{ "modal.rebranding.description" | fms }}</p>
  <img [src]="'modal.rebranding.image' | fms" alt="Rebranding transition" />
</stb-modal>
