import { Type } from "@angular/core";
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from "@angular/router";
import { last } from "lodash-es";
import { getIsNotNullable, getIsNullable } from "../utils/utils";
import { ArticlesPageComponent } from "src/app/pages/articles-page/articles-page.component";

type Component = Type<any> | string;

/**
 * Be aware!
 * Importing components from async modules (see app.routing.ts) increases
 * the main bundle file size. This is currently the case for
 * - ArticlesPageComponent
 */
const REUSABLE_COMPONENTS: Component[] = [ArticlesPageComponent];

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  private readonly storedRoutes = new Map<Component, DetachedRouteHandle>();

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const component = getComponentFromRoute(route);
    return isReusableComponent(component);
  }

  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const component = getComponentFromRoute(route);

    if (isReusableComponent(component) && getIsNotNullable(component)) {
      this.storedRoutes.set(component, handle);
    }
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const component = getComponentFromRoute(route);

    if (getIsNullable(component)) {
      return false;
    }

    return !!this.storedRoutes.get(component);
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const component = getComponentFromRoute(route);

    if (getIsNullable(component)) {
      return null;
    }
    return this.storedRoutes.get(component) || null;
  }
}

function getComponentFromRoute(route: ActivatedRouteSnapshot): Component | null {
  return <Component>last(route.pathFromRoot)?.component;
}

function isReusableComponent(component: Component | null): boolean {
  if (getIsNullable(component)) {
    return false;
  }
  return REUSABLE_COMPONENTS.includes(component);
}
