<div class="devmode-indicator">
  <button [stbButton]="'outlined'" [size]="'small'" [rebrand]="true" (click)="toggleDevPanel()">
    {{ devModeIndicator$ | async }}
  </button>
</div>
@if (devPanelToggle) {
  <div class="dev">
    <div class="buttons-container">
      <div class="toggles-container">
        <button
          [size]="'small'"
          [rebrand]="true"
          [stbButton]="envToggle ? 'contained' : 'outlined'"
          (click)="toggleEnv()"
        >
          Env
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [stbButton]="remoteConfigToggle ? 'contained' : 'outlined'"
          (click)="toggleRemoteConfig()"
        >
          RemoteConfig
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [stbButton]="customerToggle ? 'contained' : 'outlined'"
          (click)="toggleCustomer()"
        >
          Customer
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [stbButton]="consentsToggle ? 'contained' : 'outlined'"
          (click)="toggleConsents()"
        >
          Consents
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [stbButton]="simParamsToggle ? 'contained' : 'outlined'"
          (click)="toggleSimParams()"
        >
          Sim-params
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [stbButton]="profileToggle ? 'contained' : 'outlined'"
          (click)="toggleProfile()"
        >
          Profile
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [stbButton]="clientDataToggle ? 'contained' : 'outlined'"
          (click)="toggleClientData()"
        >
          ClientData
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [disabled]="offlineMode"
          [stbButton]="keyCloakTokenToggle ? 'contained' : 'outlined'"
          (click)="toggleKeycloakToken()"
        >
          KC Tok
        </button>
        <button [size]="'small'" [rebrand]="true" [stbButton]="'outlined'" (click)="toggleAdvisorMode()">
          Advisor
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [stbButton]="sessionStorageToggle ? 'contained' : 'outlined'"
          (click)="toggleSessionStorage()"
        >
          SessionStorage
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [stbButton]="lifeExpectancyToggle ? 'contained' : 'outlined'"
          (click)="toggleLifeExpectancy()"
        >
          LifeExpectancy
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [stbButton]="publicPensionDataToggle ? 'contained' : 'outlined'"
          (click)="togglePublicPensionData()"
        >
          PublicPensionData
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [stbButton]="showFeatureTogglesPanelToggle ? 'contained' : 'outlined'"
          (click)="showFeatureTogglesPanel()"
        >
          FeatureToggles
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [disabled]="offlineMode"
          [stbButton]="'outlined'"
          (click)="copyToken()"
        >
          Copy token
        </button>
        <button [size]="'small'" [rebrand]="true" [stbButton]="'outlined'" (click)="generateDummySentryError()">
          Generate Sentry Error
        </button>
        <button
          [size]="'small'"
          [rebrand]="true"
          [stbButton]="'outlined'"
          [disabled]="!dispatchLogging"
          (click)="toggleDispatcherLogging()"
        >
          Dispatch Logging
        </button>
        <button [size]="'small'" [rebrand]="true" [stbButton]="'outlined'" (click)="toggleKronAgreements()">
          {{ (kronAgreementsToggle$ | async) ? "Hide Kron Agreements" : "Show Kron Agreements" }}
        </button>
        <div class="nin font-heading-5" (click)="copyNin()">NIN: {{ nin$ | async }}</div>
      </div>
      <div class="icon-settings-container">
        <app-pin [toggle]="stickyToggle$ | async" (click)="toggleSticky()"></app-pin>
        <button
          (click)="toggleUserSettingsPanel()"
          [type]="'icon'"
          [stbButton]="'icon'"
          [colorVariant]="'brand'"
          [rebrand]="true"
          [iconType]="'settings'"
          [iconSize]="'medium'"
          [setIconBefore]="true"
          [disabled]="false"
          [size]="'small'"
          aria-label="icon button"
        ></button>
      </div>
    </div>
    <section class="mb-16">
      <app-api-fetch-menu></app-api-fetch-menu>
    </section>
    <div class="toggles-container">
      <stb-form [rebrand]="true">
        <select (change)="navigateToPage($event)">
          <option value="">Gå til -></option>
          @for (route of pages | keyvalue; track route) {
            <option [value]="route.value">{{ route.key }}</option>
          }
        </select>
      </stb-form>

      <stb-form [rebrand]="true">
        <select (change)="goToPensionPlanPage($event)">
          <option value="">pensionPlan page</option>
          @for (route of pensionPlanRoutes$ | async; track route) {
            <option [value]="route.url">{{ route.key }}</option>
          }
        </select>
      </stb-form>
      <stb-form [rebrand]="true">
        <select
          [value]="SmartAccountState[(smartAccountService.smartAccountState$ | async)!]"
          (change)="smartAccountStateSelect($event)"
        >
          <option value="">smartAccount state</option>
          @for (stateKey of smartAccountStateKeys(); track stateKey) {
            <option [value]="stateKey">{{ stateKey }}</option>
          }
        </select>
      </stb-form>
      <stb-form [rebrand]="true">
        <select
          [value]="ExtrapensionState[(extrapensionService.extrapensionState$ | async)!]"
          (change)="extrapensionStateSelect($event)"
        >
          <option value="">extrapension state</option>
          @for (stateKey of extrapensionStateKeys(); track stateKey) {
            <option [value]="stateKey">{{ stateKey }}</option>
          }
        </select>
      </stb-form>
      <stb-form [rebrand]="true">
        <select [value]="ipsStateValue$ | async" (change)="ipsStateSelect($event)">
          <option value="">ips state</option>
          @for (stateKey of ipsStateValues(); track stateKey) {
            <option [value]="stateKey">{{ stateKey }}</option>
          }
        </select>
      </stb-form>
    </div>
    <div class="toggles-container">
      <button [size]="'small'" [rebrand]="true" [stbButton]="'outlined'" (click)="stepUpAuth()">Step Up Auth</button>
      <button [size]="'small'" [rebrand]="true" [stbButton]="'outlined'" (click)="logout()">
        {{ "header.navigation.log_off.text" | fms }}
      </button>
      <button [size]="'small'" [rebrand]="true" [stbButton]="'outlined'" (click)="setLogoutCookie()">
        Set QSI Logout Cookies
      </button>
      <a href="../smart-pensjon/pensjonsplan/mine-penger?displaymode=app">
        <button [size]="'small'" [rebrand]="true" [stbButton]="'outlined'">Gå til Mine Penger</button>
      </a>
    </div>

    <div class="jobs-container">
      <div>
        <div class="jobs-item">
          <p class="font-body-demibold mr-12">GlobalSpinner</p>
          <button
            [size]="'small'"
            [rebrand]="true"
            [stbButton]="'contained'"
            (click)="globalRunningJobsService.forceStop()"
          >
            pop
          </button>
        </div>
        {{ globalRunningJobsService.runningJobs$ | async | json }}
      </div>
      <div>
        <div class="spinner-headline-wrapper">
          <p class="font-body-demibold mr-12">PrognosesSpinner</p>
          <button
            [rebrand]="true"
            [stbButton]="'contained'"
            [size]="'small'"
            (click)="fetchPrognosesRunningJobsService.forceStop()"
          >
            pop
          </button>
        </div>
        {{ prognosesSpinner$ | async | json }}
      </div>
    </div>
    @if (errorsService.hasCriticalErrors$ | async) {
      <div class="spinner-reload">
        @for (e of this.errorsService.criticalErrors$ | async; track e) {
          <stb-alert [surfaceVariant]="'surface-1'" [heading]="e.status.toString()" [rebrand]="true">
            <p>{{ e.statusText }}</p>
            <p>{{ e.message }}</p>
          </stb-alert>
        }
        <button [size]="'small'" [stbButton]="'contained'" [rebrand]="true" (click)="refreshClicked()">
          {{ "errorspage.button.reload" | fms }}
        </button>
      </div>
    }
    @if (envToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ env | json }}</pre>
      </div>
    }
    @if (remoteConfigToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ remoteConfig$ | async | json }}</pre>
      </div>
    }
    @if (customerToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ customer$ | async | json }}</pre>
      </div>
    }
    @if (consentsToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre *appLet="'Ikke tatt stilling til' as freshConsent">
          storageConsent: {{ (this.consentService.customerSuppliedDataConsent$ | async) ?? freshConsent }}
          norskPensjonConsent: {{ (this.consentService.hasNorskPensjonConsent$ | async) ?? freshConsent }}
          purchaseAssistanceConsentValue: {{
            (this.consentService.purchaseAssistanceConsentValue$ | async) ?? freshConsent
          }}
          navConsentValue: {{ (this.customerService.navConsent$ | async) ?? freshConsent }}
          ofaConsentValue: {{ (this.customerService.ofaConsent$ | async) ?? freshConsent }}
          extractAllConsents(): {{ this.consentService.extractAllConsents() | json }}
          </pre
        >
      </div>
    }
    @if (simParamsToggle) {
      <div class="data-panel simulation-params" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>
            annualGrossIncome: {{ this.incomeService.annualGrossIncome$ | async }}
          startPayoutAge: {{ startPayoutAge$ | async }}
          startPayoutAgePartialWithdrawal: {{ startPayoutAgePartialWithdrawal$ | async }}
          startPayoutAgeRange: {{ startPayoutAgeRange$ | async }}
          partTimePercentage: {{ partTimePercentage$ | async }}
          withdrawalPercentage: {{ withdrawalPercentage$ | async }}
          postPensionPartTimeEnable: {{ this.commonParametersService.postPensionPartTimeEnable$ | async }}
          postPensionPartTimeEndAge: {{ this.commonParametersService.postPensionPartTimeEndAge$ | async }}
          postPensionPartTimePercent: {{ this.commonParametersService.postPensionPartTimePercent$ | async }}</pre
        >
      </div>
    }
    @if (profileToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ profile$ | async | json }}</pre>
      </div>
    }
    @if (clientDataToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ clientData$ | async | json }}</pre>
      </div>
    }
    @if (keyCloakTokenToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ keycloakService.parsedIdToken$ | async | json }}</pre>
      </div>
    }
    @if (sessionStorageToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ sessionStorageService.getAppData() | json }}</pre>
      </div>
    }
    @if (lifeExpectancyToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ lifeExpectancyData$ | async | json }}</pre>
      </div>
    }
    @if (publicPensionDataToggle) {
      <div class="data-panel" cdkDrag>
        <div class="drag-handle" cdkDragHandle></div>
        <pre>{{ publicPensionData$ | async | json }}</pre>
      </div>
    }
    <ng-container *appLet="featureToggles$ | async | keyvalue as featureToggles">
      @if (showFeatureTogglesPanelToggle) {
        <div class="data-panel" cdkDrag>
          <div class="drag-handle" cdkDragHandle></div>
          @for (featureToggle of featureToggles; track featureToggle) {
            <div class="clickable" (click)="toggleFeatureOverride(featureToggle.key)">
              <stb-checkbox [fluid]="true">
                <input type="checkbox" [checked]="featureToggle.value === true" />
                <label>{{ featureToggle.key }}</label>
              </stb-checkbox>
            </div>
          }
        </div>
      }
    </ng-container>
  </div>
}

@if (isOpenUserSettings$ | async) {
  <app-user-settings-panel></app-user-settings-panel>
}
