import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FmsPipe } from "src/app/modules/shared/pipes/fms.pipe";
import { ModalModule } from "@storeblocks/modal-ng";
import { DialogService } from "src/app/services/dialog/dialog.service";

@Component({
  selector: "app-rebranding-modal",
  standalone: true,
  imports: [FmsPipe, ModalModule],
  templateUrl: "./rebranding-modal.component.html",
  styleUrl: "./rebranding-modal.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RebrandingModalComponent {
  constructor(private readonly dialogService: DialogService) {}

  public close(): void {
    this.dialogService.closeAll();
  }
}
