<stb-accordion [rebrand]="true">
  <stb-accordion-item [headerText]="headerText$ | async">
    <p>{{ roleInfo$ | async }}</p>
    <ul>
      @for (role of advisorResourceRoles$ | async; track role) {
        <li>
          <p>
            {{ this.fmsService.translateAsync(role) | async }}
          </p>
        </li>
      }
    </ul>
  </stb-accordion-item>
</stb-accordion>
