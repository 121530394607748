import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, ObservedValueOf, Subject, firstValueFrom, of } from "rxjs";
import { combineLatestWith, map } from "rxjs/operators";
import { RouteKey as ArticlesRouteKey, routes as articleRoutes } from "src/app/pages/articles-page/routes";
import { RouteKey as ContractsRouteKey, routes as contractsRoutes } from "src/app/pages/contracts-page/routes";
import { RouteKey as PlanRouteKey, routes as pensionPlanRoutes } from "src/app/modules/pension-plan/routes";
import {
  RouteKey as SmartAccountRouteKey,
  routes as smartAccountRoutes,
} from "src/app/pages/smart-account-page/routes";
import { RouteKey, routes } from "src/app/routes";
import { CommonParametersService } from "src/app/services/common-parameters.service";
import { ConsentService } from "src/app/services/consent.service";
import { CopyValueToClipboardService } from "src/app/services/copy-value-to-clipboard.service";
import { ClientDataService } from "src/app/services/customer-supplied-data/client-data.service";
import { ProfileService } from "src/app/services/customer-supplied-data/profile.service";
import { CustomerService, getNin } from "src/app/services/customer.service";
import { DispatcherService } from "src/app/services/dispatcher.service";
import { EndpointService } from "src/app/services/endpoint.service";
import { ErrorsService } from "src/app/services/errors.service";
import { ExtrapensionService, ExtrapensionState } from "src/app/services/extrapension.service";
import { FeatureToggleService, OverridableFeatureTogglesMap } from "src/app/services/feature-toggle.service";
import { IncomeService } from "src/app/services/income/income.service";
import { KeycloakService } from "src/app/services/keycloak.service";
import { NavigateToPensionPlanService } from "src/app/services/navigate-to-pension-plan.service";
import { PensionPlanProgressService } from "src/app/services/pension-plan-progress.service";
import { PublicPensionService } from "src/app/services/prognoses-services/public-pension.service";
import { QualtricsService } from "src/app/services/qualtrics.service";
import { RemoteConfigService } from "src/app/services/remote-config.service";
import { FetchPrognosesRunningJobsService } from "src/app/services/running-jobs/fetch-prognoses-running-jobs.service";
import { GlobalRunningJobsService } from "src/app/services/running-jobs/global-running-jobs.service";
import { SessionStorageService, StorageKey } from "src/app/services/session-storage.service";
import { SmartAccountService, SmartAccountState } from "src/app/services/smart-account.service";
import { StartPayoutAgeService } from "src/app/services/start-payout-age.service";
import { Log } from "src/app/utils/log";
import { Monitoring } from "src/app/utils/monitoring";
import { BehaviorStore } from "src/app/utils/rxjs/store";
import { getEnumKeys } from "src/app/utils/utils";
import { environment } from "src/environments/environment";

import { ApiFetchMenuComponent } from "./api-fetch-menu/api-fetch-menu.component";
import { AsyncPipe, JsonPipe, KeyValuePipe } from "@angular/common";
import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";
import { CheckboxModule } from "@storeblocks/checkbox-ng";
import { UserSettingsPanelComponent } from "./user-settings-panel/user-settings-panel.component";
import { AlertModule } from "@storeblocks/alert-ng";
import { select$ } from "src/app/utils/rxjs/select";
import { ButtonModule } from "@storeblocks/button-ng";
import { FormModule } from "@storeblocks/form-ng";
import { PinComponent } from "src/app/components/pin/pin.component";
import { LetDirective } from "src/app/modules/shared/directives/let.directive";
import { FmsPipe } from "src/app/modules/shared/pipes/fms.pipe";
import {
  ActionIpsService,
  IpsAction,
} from "src/app/modules/features/pension-actions/components/action-ips/action-ips.service";
import { DevService } from "src/app/services/dev.service";

interface HostCssClasses {
  sticky: boolean;
}

@Component({
  selector: "app-dev",
  templateUrl: "./dev.component.html",
  styleUrls: ["./dev.component.scss"],
  standalone: true,
  imports: [
    ApiFetchMenuComponent,
    PinComponent,
    CdkDrag,
    CdkDragHandle,
    LetDirective,
    CheckboxModule,
    UserSettingsPanelComponent,
    AsyncPipe,
    JsonPipe,
    KeyValuePipe,
    FmsPipe,
    AlertModule,
    ButtonModule,
    FormModule,
  ],
})
export class DevComponent implements OnInit, OnDestroy {
  @HostBinding("class")
  public hostCssClasses!: string;

  public devPanelToggle!: boolean;
  env = environment;
  remoteConfig$ = this.remoteConfigService.getAllConfigs$();
  customer$ = this.customerService.customer$;
  nin$ = this.customerService.customer$.pipe(map((customer) => getNin(customer)));
  profile$ = this.profileService.profile$;
  clientData$: Observable<{
    clientDataWrapper: CustomerSuppliedData.ClientDataWrapper;
    simulationParametersByEngagementEnable: ObservedValueOf<
      typeof ClientDataService.prototype.simulationParametersByEngagementEnable$
    >;
    simulationParametersPartialWithdrawalByEngagementEnable: ObservedValueOf<
      typeof ClientDataService.prototype.simulationParametersPartialWithdrawalEnable$
    >;
  }>;
  public offlineMode = environment.offlineMode;
  public devModeIndicator$!: Observable<string | undefined>;

  public readonly SmartAccountState = SmartAccountState;
  public readonly ExtrapensionState = ExtrapensionState;
  public readonly IpsAction = IpsAction;

  lifeExpectancyData$: Observable<unknown> = this.customerService.age$.pipe(
    combineLatestWith(this.customerService.dateOfBirth$, this.customerService.ageInMonths$),
    map(([age, dateOfBirth, ageInMonths]) => ({ customerAge: age, ageInMonths, dateOfBirth })),
  );
  publicPensionData$: typeof PublicPensionService.prototype.hotStreamsOfSimulationParametersByEngagement$ =
    this.publicPensionService.hotStreamsOfSimulationParametersByEngagement$;

  public readonly featureToggles$: Observable<OverridableFeatureTogglesMap>;
  public readonly startPayoutAge$ = this.startPayoutAgeService.getStartPayoutAge();
  public readonly startPayoutAgePartialWithdrawal$: Observable<number>;
  public readonly startPayoutAgeRange$ = this.startPayoutAgeService.getStartPayoutAgeRange();
  public readonly partTimePercentage$: Observable<number>;
  public readonly withdrawalPercentage$: Observable<number>;

  public pensionPlanRoutes$ = this.pensionPlanProgressService.planRoutes$;
  public prognosesSpinner$!: Observable<string[]>;
  public readonly pages = getPages();
  public readonly ipsStateValue$ = this.actionIpsService.getIpsAction();

  envToggle = false;
  remoteConfigToggle = false;
  customerToggle = false;
  consentsToggle = false;
  simParamsToggle = false;
  profileToggle = false;
  clientDataToggle = false;
  keyCloakTokenToggle = false;
  sessionStorageToggle = false;
  lifeExpectancyToggle = false;
  publicPensionDataToggle = false;
  showFeatureTogglesPanelToggle = false;
  dispatchLogging = true;
  kronAgreementsToggle$: Observable<boolean>;

  private readonly destroy$: Subject<void> = new Subject();
  private readonly _stickyToggle$ = new BehaviorStore(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly stickyToggle$ = this._stickyToggle$.asObservable();
  private readonly _isOpenUserSettings$ = new BehaviorStore(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly isOpenUserSettings$ = this._isOpenUserSettings$.asObservable();

  private readonly _hostCssClasses$ = new BehaviorStore<HostCssClasses>({
    sticky: false,
  });

  constructor(
    public readonly router: Router,
    public clientDataService: ClientDataService,
    public commonParametersService: CommonParametersService,
    public consentService: ConsentService,
    public customerService: CustomerService,
    public dispatcherService: DispatcherService,
    public errorsService: ErrorsService,
    public globalRunningJobsService: GlobalRunningJobsService,
    public incomeService: IncomeService,
    public keycloakService: KeycloakService,
    public profileService: ProfileService,
    public publicPensionService: PublicPensionService,
    public fetchPrognosesRunningJobsService: FetchPrognosesRunningJobsService,
    public endpointService: EndpointService,
    public smartAccountService: SmartAccountService,
    public readonly sessionStorageService: SessionStorageService,
    public readonly qualtricsService: QualtricsService,
    private readonly featureToggleService: FeatureToggleService,
    public readonly extrapensionService: ExtrapensionService,
    private readonly pensionPlanProgressService: PensionPlanProgressService,
    private readonly remoteConfigService: RemoteConfigService,
    private readonly copyValueToClipboardService: CopyValueToClipboardService,
    private readonly navigateToPensionPlanService: NavigateToPensionPlanService,
    public readonly startPayoutAgeService: StartPayoutAgeService,
    public readonly actionIpsService: ActionIpsService,
    public readonly devService: DevService,
  ) {
    this.clientData$ = this.clientDataService.clientDataWrapper$.pipe(
      combineLatestWith(
        this.clientDataService.simulationParametersByEngagementEnable$,
        this.clientDataService.simulationParametersPartialWithdrawalEnable$,
      ),
      map(([clientDataWrapper, enable, partialEnable]) => ({
        clientDataWrapper,
        simulationParametersByEngagementEnable: enable,
        simulationParametersPartialWithdrawalByEngagementEnable: partialEnable,
      })),
    );

    this.kronAgreementsToggle$ = this.devService.kronAgreementsToggle$;

    this.featureToggles$ = this.featureToggleService.getOverridableFeatureToggleMap();

    this.startPayoutAgePartialWithdrawal$ = select$(this.clientDataService.clientDataWrapper$, (data) =>
      Number(data.clientData?.simulationParameters.startPayoutAgePartialWithdrawal),
    );

    this.partTimePercentage$ = select$(this.clientDataService.clientDataWrapper$, (data) =>
      Number(data.clientData?.simulationParameters.partTimePercentage),
    );

    this.withdrawalPercentage$ = select$(this.clientDataService.clientDataWrapper$, (data) =>
      Number(data.clientData?.simulationParameters.withdrawalPercentage),
    );
  }

  ngOnInit(): void {
    this.devModeIndicator$ = of(environment.appVersion);

    this.devPanelToggle = !!this.sessionStorageService.get<boolean>(StorageKey.DevPanelToggle, false);

    this.stickyToggle$.subscribe((sticky) => this.toggleHostCss({ sticky }));

    this.pensionPlanRoutes$.subscribe();

    this.prognosesSpinner$ = this.fetchPrognosesRunningJobsService.runningJobs$.pipe(
      combineLatestWith(this.fetchPrognosesRunningJobsService.runningEngagementJobs$),
      map(([runningJobs, runningEngagementJobs]) => [...runningJobs, ...runningEngagementJobs]),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  public refreshClicked(): void {
    this.errorsService.clearCriticalErrors();
    this.globalRunningJobsService.forceStop();
    this.fetchPrognosesRunningJobsService.forceStop();
    this.router.navigate(["."]);
  }

  toggleDevPanel(): void {
    const next: boolean = !this.devPanelToggle;
    this.sessionStorageService.set(StorageKey.DevPanelToggle, next);
    this.devPanelToggle = next;
  }

  toggleEnv(): void {
    this.envToggle = !this.envToggle;
  }

  toggleRemoteConfig(): void {
    this.remoteConfigToggle = !this.remoteConfigToggle;
  }

  toggleCustomer(): void {
    this.customerToggle = !this.customerToggle;
  }

  toggleConsents(): void {
    this.consentsToggle = !this.consentsToggle;
  }

  toggleSimParams(): void {
    this.simParamsToggle = !this.simParamsToggle;
  }

  toggleProfile(): void {
    this.profileToggle = !this.profileToggle;
  }

  toggleClientData(): void {
    this.clientDataToggle = !this.clientDataToggle;
  }

  toggleKeycloakToken(): void {
    this.keyCloakTokenToggle = !this.keyCloakTokenToggle;
  }

  toggleAdvisorMode(): void {
    this.keycloakService.toggleForcedAdvisorMode();
  }

  toggleSessionStorage(): void {
    this.sessionStorageToggle = !this.sessionStorageToggle;
  }

  toggleLifeExpectancy(): void {
    this.lifeExpectancyToggle = !this.lifeExpectancyToggle;
  }

  togglePublicPensionData(): void {
    this.publicPensionDataToggle = !this.publicPensionDataToggle;
  }

  showFeatureTogglesPanel(): void {
    this.showFeatureTogglesPanelToggle = !this.showFeatureTogglesPanelToggle;
  }

  generateDummySentryError(): void {
    Monitoring.error("Generating dummy error...");
    Log.error("Generated dummy Sentry error...");
  }

  stepUpAuth(): void {
    this.keycloakService.stepUpAuth();
  }

  logout(): void {
    this.keycloakService.logout({ redirectUri: "https://www.storebrand.no/logg-ut/" });
  }

  toggleDispatcherLogging(): void {
    this.dispatchLogging = this.dispatcherService.toggleLogging();
  }

  smartAccountStateKeys(): ReadonlyArray<string> {
    return getEnumKeys(this.SmartAccountState);
  }

  smartAccountStateSelect(event: any): void {
    if (event && event.target && event.target.value) {
      this.smartAccountService.overrideSmartAccount(this.SmartAccountState[event.target.value as SmartAccountState]);
    }
  }

  extrapensionStateKeys(): ReadonlyArray<string> {
    return getEnumKeys(this.ExtrapensionState);
  }

  ipsStateValues(): ReadonlyArray<string> {
    return Object.values(this.IpsAction);
  }

  extrapensionStateSelect(event: any): void {
    if (event && event.target && event.target.value) {
      this.extrapensionService.overrideExtrapension(Number(this.ExtrapensionState[event.target.value]));
    }
  }

  ipsStateSelect(event: any): void {
    if (event && event.target && event.target.value) {
      this.actionIpsService.overrideIps(event.target.value);
    }
  }

  setLogoutCookie(): void {
    this.qualtricsService.setCookies();
  }

  toggleKronAgreements(): void {
    this.devService.toggleKronAgreements();
  }

  public async copyToken(): Promise<void> {
    const token = await this.keycloakService.getToken();
    this.copyValueToClipboardService.copy(token ?? "", "Token copied!");
  }

  public async copyNin(): Promise<void> {
    const nin = await firstValueFrom(this.nin$);
    this.copyValueToClipboardService.copy(nin ?? "", "Nin copied!");
  }

  public toggleSticky(): void {
    this._stickyToggle$.next(!this._stickyToggle$.getValue());
  }

  public toggleUserSettingsPanel(): void {
    this._isOpenUserSettings$.next(!this._isOpenUserSettings$.getValue());
  }

  public toggleFeatureOverride(key: string): void {
    this.featureToggleService.setOverride(key);
  }

  public navigateToPage(event: any): void {
    if (event && event.target && event.target.value) {
      const isPensionPlanRoute = event.target.value === pensionPlanRoutes[PlanRouteKey.Root];
      if (isPensionPlanRoute) {
        this.navigateToPensionPlanService.navigateToProgressUrl();
      } else {
        this.router.navigate([event.target.value], { queryParamsHandling: "merge" });
      }
    }
  }

  public goToPensionPlanPage(event: any): void {
    if (event && event.target && event.target.value) {
      this.router.navigate([event.target.value], { queryParamsHandling: "merge" });
    }
  }

  private toggleHostCss(classes: Partial<HostCssClasses>): void {
    // First we update the store
    this._hostCssClasses$.next({
      ...this._hostCssClasses$.getValue(),
      ...classes,
    });
    // Then we apply the change to host
    this.hostCssClasses = filterActive<HostCssClasses>(this._hostCssClasses$.getValue()).join(" ");
  }
}

function filterActive<T extends object>(classes: T): (keyof T)[] {
  type Keys = keyof typeof classes;
  const activeClasses: [Keys, string][] = Object.entries(classes).filter(([, value]) => value) as [Keys, string][];
  const objectOfActiveClasses: Partial<T> = Object.fromEntries(activeClasses) as unknown as Partial<T>; // To the reader; please make the type work if you can
  return Object.keys(objectOfActiveClasses) as Array<keyof typeof objectOfActiveClasses>;
}

function getPages(): Map<string, string> {
  return new Map([
    ["Dashboard", routes[RouteKey.Root]],
    ["Concept", routes[RouteKey.Concept]],
    ["PensionPlan", pensionPlanRoutes[PlanRouteKey.Root]],
    ["ContactUs", routes[RouteKey.ContactUs]],
    ["SmartAccount", smartAccountRoutes[SmartAccountRouteKey.Root]],
    ["Articles", articleRoutes[ArticlesRouteKey.Root]],
    ["Avtaler", contractsRoutes[ContractsRouteKey.Root]],
  ]);
}
