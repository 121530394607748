import { Component } from "@angular/core";
import {
  AlertKey,
  InformationMessageComponent,
} from "src/app/modules/shared/components/alerts/information-message/information-message.component";
import { ButtonModule } from "@storeblocks/button-ng";
import { FmsService } from "src/app/services/fms.service";
import { AsyncPipe } from "@angular/common";

@Component({
  selector: "app-errors",
  templateUrl: "errors.component.html",
  styleUrls: ["errors.component.scss"],
  standalone: true,
  imports: [InformationMessageComponent, ButtonModule, AsyncPipe],
})
export class ErrorsComponent {
  public readonly AlertKey = AlertKey;

  public readonly errors = {
    headline$: this.fmsService.translateAsync("errorspage.headline"),
    information$: this.fmsService.translateAsync("errorspage.information"),
    buttonText$: this.fmsService.translateAsync("errorspage.button.reload"),
    image$: this.fmsService.translateAsync("errorspage.image"),
  };

  constructor(private readonly fmsService: FmsService) {}

  public reloadPage(): void {
    location.reload();
  }
}
