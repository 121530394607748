import { Routes } from "@angular/router";
import { RouteKey, routes } from "./routes";
import { ArticleResolver } from "src/app/pages/articles-page/resolvers/article.resolver";

export const articlesLazyRoutes: Routes = [
  {
    path: "",
    loadComponent: () => import("./articles-page.component").then((c) => c.ArticlesPageComponent),
  },
  {
    path: routes[RouteKey.Article],
    data: {
      breadcrumb: "skip-breadcrumb",
    },
    children: [
      {
        path: routes.pensionReturnsIn2023,
        loadComponent: () =>
          import("./pages/pension-returns-in-2023/pension-returns-in-2023.component").then(
            (c) => c.PensionReturnsIn2023Component,
          ),
      },
      {
        path: routes.backPayments,
        loadComponent: () =>
          import("./pages/tilbakebetale-pensjon/tilbakebetale-pensjon.component").then(
            (c) => c.AppArticleBackPaymentsComponent,
          ),
        data: {
          breadcrumb: "breadcrumbs.faq.backPayments",
        },
      },
      {
        path: "**",
        loadComponent: () => import("./pages/article-page/article-page.component").then((c) => c.ArticlePageComponent),
        resolve: { breadcrumb: ArticleResolver },
      },
    ],
  },
];
