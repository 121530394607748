import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DispatcherService, Signal } from "src/app/services/dispatcher.service";
import { ButtonModule } from "@storeblocks/button-ng";
import { FmsPipe } from "../../pipes/fms.pipe";
import { ModalModule } from "@storeblocks/modal-ng";
import { DialogService } from "src/app/services/dialog/dialog.service";

@Component({
  templateUrl: "./refresh-modal.component.html",
  standalone: true,
  imports: [ButtonModule, FmsPipe, ModalModule],
})
export class RefreshModalComponent implements OnDestroy {
  constructor(private readonly dialogService: DialogService) {}

  public refresh(): void {
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.refresh();
  }

  public close(): void {
    this.dialogService.closeAll();
  }
}

@Component({
  selector: "app-refresh-modal",
  template: "",
  standalone: true,
})
export class RefreshModalHostComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly dispatcherService: DispatcherService,
    private readonly dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.dispatcherService
      .subscribeTo$(Signal.ChunkLoadError, RefreshModalComponent.name)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.openDialog());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private openDialog(): void {
    this.dialogService.open(RefreshModalComponent);
  }
}
