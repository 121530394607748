import { Routes } from "@angular/router";
import { RouteKey, deprecatedRoutes, routes } from "src/app/modules/pension-plan/routes";
import { redirectLastVistedUrlGuardResolver } from "./guards/redirect-last-visited-url-guard.resolver";
import { NavProgressElements } from "./pension-plan.component";

const redirectedRoutes: Routes = [
  {
    path: deprecatedRoutes[RouteKey.Needs],
    redirectTo: routes[RouteKey.Needs],
  },
  {
    path: deprecatedRoutes[RouteKey.NeedsIntro],
    redirectTo: routes[RouteKey.NeedsIntro],
  },
  {
    path: deprecatedRoutes[RouteKey.Todo],
    redirectTo: routes[RouteKey.Actions],
  },
  {
    path: deprecatedRoutes[RouteKey.TodoIntro],
    redirectTo: routes[RouteKey.ActionsIntro],
  },
  {
    path: deprecatedRoutes[RouteKey.WithdrawalOrder],
    redirectTo: routes[RouteKey.Summary],
  },
];

export const pensionPlanLazyRoutes: Routes = [
  {
    path: "",
    loadComponent: () => import("./pension-plan.component").then((c) => c.PensionPlanComponent),
    children: [
      ...redirectedRoutes,
      {
        path: "",
        resolve: { redirectLastVistedUrlGuardResolver },
        children: [],
      },
      {
        path: routes[RouteKey.AgreementsIntro],
        loadComponent: () =>
          import("./pages/intro-pages/agreements-intro-page/agreements-intro-page.component").then(
            (c) => c.AgreementsIntroPageComponent,
          ),
        data: {
          breadcrumb: "breadcrumbs.pensionPlan.agreementsIntro",
        },
      },
      {
        path: routes[RouteKey.Agreements],
        loadComponent: () =>
          import("./pages/agreements-page/agreements-page.component").then((c) => c.AgreementsPageComponent),
        data: {
          navProgressElements: null,
          breadcrumb: "breadcrumbs.pensionPlan.agreements",
        },
      },
      {
        path: routes[RouteKey.AccumulationIntro],
        loadComponent: () =>
          import("./pages/intro-pages/accumulation-intro-page/accumulation-intro-page.component").then(
            (c) => c.AccumulationIntroPageComponent,
          ),
        data: {
          breadcrumb: "breadcrumbs.pensionPlan.accumulationIntro",
        },
      },
      {
        path: routes[RouteKey.Accumulation],
        loadComponent: () =>
          import("./pages/accumulation-page/accumulation-page.component").then((c) => c.AccumulationPageComponent),
        data: {
          navProgressElements: [NavProgressElements.Parameters],
          breadcrumb: "breadcrumbs.pensionPlan.accumulation",
        },
      },
      {
        path: routes[RouteKey.NeedsIntro],
        loadComponent: () =>
          import("./pages/intro-pages/needs-intro-page/needs-intro-page.component").then(
            (c) => c.NeedsIntroPageComponent,
          ),
        data: {
          breadcrumb: "breadcrumbs.pensionPlan.needsIntro",
        },
      },
      {
        path: routes[RouteKey.Needs],
        loadComponent: () => import("./pages/needs-page/needs-page.component").then((c) => c.NeedsPageComponent),
        data: {
          navProgressElements: [NavProgressElements.Parameters],
          breadcrumb: "breadcrumbs.pensionPlan.needs",
        },
      },
      {
        path: routes[RouteKey.ActionsIntro],
        loadComponent: () =>
          import("./pages/intro-pages/todo-intro-page/actions-intro-page.component").then(
            (c) => c.ActionsIntroPageComponent,
          ),
        data: {
          breadcrumb: "breadcrumbs.pensionPlan.actionsIntro",
        },
      },
      {
        path: routes[RouteKey.Actions],
        loadComponent: () => import("./pages/actions-page/actions-page.component").then((c) => c.ActionsPageComponent),
        data: {
          navProgressElements: null,
          breadcrumb: "breadcrumbs.pensionPlan.actions",
        },
      },
      {
        path: routes[RouteKey.SummaryIntro],
        loadComponent: () =>
          import("./pages/intro-pages/summary-intro-page/summary-intro-page.component").then(
            (c) => c.SummaryIntroPageComponent,
          ),
        data: {
          breadcrumb: "breadcrumbs.pensionPlan.summaryIntro",
        },
      },
      {
        path: routes[RouteKey.Summary],
        loadComponent: () => import("./pages/summary-page/summary-page.component").then((c) => c.SummaryPageComponent),
        data: {
          navProgressElements: [NavProgressElements.Parameters],
          breadcrumb: "breadcrumbs.pensionPlan.summary",
        },
      },
    ],
  },
  {
    path: routes[RouteKey.MinePenger],
    loadComponent: () =>
      import("./pages/mine-penger-page/mine-penger-page.component").then((c) => c.MinePengerPageComponent),
    data: {
      breadcrumb: "breadcrumbs.pensionPlan.minePenger",
    },
  },
  {
    path: routes[RouteKey.Landing],
    loadComponent: () =>
      import("./pages/pension-plan-landing-page/pension-plan-landing-page.component").then(
        (c) => c.PensionPlanLandingPageComponent,
      ),
    data: {
      breadcrumb: "breadcrumbs.pensionPlan.landing",
    },
  },
  {
    path: routes[RouteKey.RescueEpkS],
    loadComponent: () =>
      import("./pages/rescue-epk-s-page/rescue-epk-s-page.component").then((c) => c.RescueEpkSPageComponent),
    data: {
      breadcrumb: "breadcrumbs.pensionPlan.rescueEpkS",
    },
  },
  {
    path: routes[RouteKey.CalculationDisclaimer],
    loadComponent: () =>
      import("./pages/calculation-disclaimer/calculation-disclaimer-page.component").then(
        (c) => c.CalculationDisclaimerPageComponent,
      ),
    data: {
      breadcrumb: "breadcrumbs.pensionPlan.calculationDisclaimer",
    },
  },
];
