import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map, delay } from "rxjs/operators";
import { DynamicArticle } from "src/app/pages/articles-page/articles-page.component";
import { FmsService } from "src/app/services/fms.service";

@Injectable({ providedIn: "root" })
export class ArticleResolver implements Resolve<string> {
  constructor(private readonly fmsService: FmsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const articleSlug = state.url.split("/").pop() || "unknown-article";

    return this.fmsService.translateAsync<DynamicArticle[]>("articles.dynamicArticles").pipe(
      map((articles) => articles.find((article) => article.key === articleSlug)?.title ?? "Unknown Article"),
      delay(1000), // Simulating async delay
    );
  }
}
